import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import { IoIosApps } from 'react-icons/io'
import { FaRobot, FaBitcoin } from 'react-icons/fa'

import Layout from '../components/layout'
import SEO from '../components/seo'
import BannerBgSvg from '../images/skylight-org.svg'

const useStyles = makeStyles(theme => ({
  banner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(32),
  },
  bannerActions: {
    paddingTop: 30,
    paddingBottom: 10,
  },
  bannerBg: {
    height: theme.spacing(32),
    backgroundImage: `url(${BannerBgSvg})`,
    backgroundRepeat: 'repeat-x',
    backgroundSize: 'auto 80%',
    backgroundPosition: 'left bottom',
  },
  touch: {
    // backgroundColor: '#0062cc',
    paddingTop: 13,
    paddingBottom: 13,
    paddingLeft: 25,
    paddingRight: 25,
    textDecoration: 'none',
    // fontWeight: 'bold',
  },
  services: {
    paddingTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  servicesTitle: {
    paddingBottom: 50,
  },
  serviceList: {
    marginBottom: 50,
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  serviceItem: {
    flex: 1,
    marginLeft: 10,
    marginRight: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  serviceIcon: { textAlign: 'center', color: 'rebeccapurple' },
  serviceTitle: { fontWeight: 'bold' },
  serviceBody: {
    color: '#4a4a4a',
    lineHeight: '30px',
  },
}))

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query SiteDescQuery {
      site {
        siteMetadata {
          description
          subDesc
        }
      }
    }
  `)
  const classes = useStyles()

  return (
    <Layout>
      <SEO title="Home" />
      <Container component="main" maxWidth="md" className={classes.banner}>
        <Typography variant="h4" gutterBottom>
          {data.site.siteMetadata.description}
        </Typography>
        <Typography variant="h5" gutterBottom>
          {data.site.siteMetadata.subDesc}
        </Typography>
        <div className={classes.bannerActions}>
          <Button
            color="primary"
            variant="contained"
            size="large"
            component={Link}
            className={classes.touch}
            to="/contact/"
          >
            Get in Touch
          </Button>
        </div>
      </Container>
      <div className={classes.bannerBg} />
      <Container component="main" maxWidth="md" className={classes.services}>
        <Typography variant="h5" className={classes.servicesTitle} gutterBottom>
          Why partner with us?
        </Typography>
        <div className={classes.serviceList}>
          <div className={classes.serviceItem}>
            <div className={classes.serviceIcon}>
              <IoIosApps size={100} />
            </div>
            <Typography
              variant="h6"
              className={classes.serviceTitle}
              align="center"
              gutterBottom
            >
              App Dev
            </Typography>
          </div>

          <div className={classes.serviceItem}>
            <div className={classes.serviceIcon}>
              <FaRobot size={100} />
            </div>
            <Typography
              variant="h6"
              className={classes.serviceTitle}
              align="center"
              gutterBottom
            >
              AI / Smart Data
            </Typography>
          </div>

          <div className={classes.serviceItem}>
            <div className={classes.serviceIcon}>
              <FaBitcoin size={100} />
            </div>
            <Typography
              variant="h6"
              className={classes.serviceTitle}
              align="center"
              gutterBottom
            >
              Blockchain R&D
            </Typography>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default IndexPage
